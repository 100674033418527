import { Button } from '@material-ui/core'
import React from 'react'
import { auth, provider } from './firebase.js'
import './Login.css'
import { useStateValue } from'./StateProvider'
import { actionTypes } from'./reducer'

const Login = () => {
 const [, dispatch] = useStateValue()

    const signIn = () => {
        auth
            .signInWithPopup(provider)
            .then((result) => {
                dispatch({
                    type: actionTypes.SET_USER,
                    user: result.user,
                })
                console.log('return',result.user)
             })
            .catch((error) => alert(error.message))
    }


  return (
      <div className='login'>
          <div className='login__container'>
              <img src='Assets/images/logo.png' alt='' />
              <div className='login__text'>
                  <h1>Sign in to SupaKyat</h1>
              </div>
              <Button  onClick={signIn}>
                  Sign in with Google
              </Button>
          </div>
          <a href="https://superjcybs.com">
          <p>Developed by &copy; SUPERJCYBS (+233247792110)</p>
          </a>
    </div>
  )
}

export default Login