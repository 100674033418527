// import firebase from 'firebase'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyA2tFY7wU8v3aF4lJ7EOLz_vxAfoi3QsjQ",
    authDomain: "tsbf-appz.firebaseapp.com",
    projectId: "tsbf-appz",
    storageBucket: "tsbf-appz.appspot.com",
    messagingSenderId: "606753096596",
    appId: "1:606753096596:web:ede88b01b39c937cd5c581",
    measurementId: "G-FW29T1LSGW"
})
const db = firebaseApp.firestore()
const auth = firebase.auth()
const provider = new firebase.auth.GoogleAuthProvider()

export { provider, auth} 
export default db