import './App.css';
import Sidebar from './Sidebar';
import Chat from './Chat'
import { Routes, Route } from 'react-router-dom'
import Login from './Login';
import { useStateValue } from './StateProvider';

const App = () => {

  const [{ user },] = useStateValue()
  console.log("App page", user)

  return (
    <div className="app">
      {!user ? (
        <Login />
      ) : (
        <div className='app__body'>
          <Routes>

            <Route path='/rooms/:roomId' element={
              <> <Sidebar /><Chat /></>
            }></Route>
            <Route path='/' element={<Sidebar/>
              // <><Sidebar /> <Chat /></>
            }></Route>
          </Routes>
        </div>
      )}
    </div>
  );
}

export default App;
